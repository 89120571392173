import React, { useState } from "react"
import {
  Box,
  Container,
  Text,
  Heading,
  Grid,
  Input,
  Button,
  Flex,
  Label,
} from "@theme-ui/components"
import { ArrowRight } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../../gatsby-plugin-theme-ui"
import { Textarea, Checkbox } from "theme-ui"
import axios from "axios"
import { OutboundLink } from "../link"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const ContactForm = ({ title, subtitle, description }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const dark = getColor(themeUiTheme, "dark")
  const isBrowser = typeof window !== "undefined"

  const [formData, setFormData] = useState({
    email: "",
    nomeCognome: "",
    ragioneSociale: "",
    telefono: "",
    comune: "",
    provincia: "",
    messaggio: "",
    urlPagina: isBrowser && window.location.href,
  })

  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const sendMail = async event => {
    event.preventDefault()

    try {
      setLoading(true)
      setError(false)

      const result = await executeRecaptcha("dynamicAction")
      if (!result) {
        throw new Error("reCAPTCHA verification failed")
      }

      const response = await axios.post(
        "/.netlify/functions/sendMail",
        formData
      )
      console.log("response", response.status)

      if (response.status === 200) {
        console.log("inside if")
        // Track successful submission
        if (typeof window !== "undefined") {
          console.log("TRIGGER EVENT")
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "form_submit",
            formType: "Contact",
            pageUrl: window.location.href,
          })
        }
        // Ensure state updates happen in order
        setLoading(false)
        setError(false)
        setSuccess(true)
      }
    } catch (error) {
      console.error("Form submission error:", error)
      setSuccess(false)
      setError(true)
      setLoading(false)
    }
  }

  return (
    <Box sx={{ py: [3, 3, 3, 4] }}>
      <Container name="contactForm">
        <Box>
          <Grid
            columns={["1fr", "1fr", "1fr", "1fr 1fr"]}
            gap={[1, 1, 1, 6]}
            sx={{ backgroundColor: "dark", p: [3, 4, 6, 6] }}
          >
            <Box>
              <Text variant="caption">{subtitle}</Text>
              <Heading
                as="div"
                variant="sectionTitle"
                sx={{
                  my: [4],
                  color: "light",
                  fontSize: [6],
                  fontWeight: "body",
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "primary",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: title }}
              />
            </Box>
            <Box>
              <Text
                as="div"
                variant="sectionTitle"
                sx={{
                  my: [4],
                  color: "light",
                  fontSize: [6],
                  fontWeight: "body",
                  letterSpacing: "-.5px",
                  lineHeight: "1.12",
                  em: {
                    fontStyle: "normal",
                    color: "primary",
                  },
                }}
                dangerouslySetInnerHTML={{ __html: description }}
              />

              <form onSubmit={sendMail}>
                <Box>
                  <Grid
                    sx={{
                      flexWrap: "wrap",
                      flexDirection: "column",
                      pb: [6, 6, 0, 0],
                    }}
                    gap={[0, 0, 0, 0]}
                  >
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              nomeCognome: e.target.value,
                            }))
                          }
                          name="nome cognome"
                          placeholder="Nome e Cognome*"
                          variant="inputs.primary"
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              ragioneSociale: e.target.value,
                            }))
                          }
                          name="ragione sociale"
                          placeholder="Ragione Sociale*"
                          variant="inputs.primary"
                          required
                        />
                      </Box>
                    </Flex>
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              email: e.target.value,
                            }))
                          }
                          name="email"
                          type="email"
                          placeholder="Email*"
                          variant="inputs.primary"
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              telefono: e.target.value,
                            }))
                          }
                          name="telefono"
                          type="text"
                          placeholder="Telefono*"
                          variant="inputs.primary"
                          required
                        />
                      </Box>
                    </Flex>
                    <Flex sx={{ flexWrap: "wrap" }}>
                      <Box
                        sx={{
                          pr: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              comune: e.target.value,
                            }))
                          }
                          name="comune"
                          placeholder="Comune*"
                          variant="inputs.primary"
                          required
                        />
                      </Box>
                      <Box
                        sx={{
                          pl: [0, 1, 1, 1],
                          pb: [3, 3, 3, 3],
                          width: ["100%", "50%"],
                        }}
                      >
                        <Input
                          onChange={e =>
                            setFormData(prevState => ({
                              ...prevState,
                              provincia: e.target.value,
                            }))
                          }
                          name="provincia"
                          placeholder="Provincia*"
                          variant="inputs.primary"
                          required
                        />
                      </Box>
                    </Flex>

                    <Textarea
                      sx={{
                        fontFamily: "body",
                        fontSize: [2],
                      }}
                      onChange={e =>
                        setFormData(prevState => ({
                          ...prevState,
                          messaggio: e.target.value,
                        }))
                      }
                      name="messaggio"
                      placeholder="Messaggio"
                      variant="inputs.primary"
                      type="textarea"
                    />
                    <LabeledCheckbox required={true}>
                      <Box>
                        <Text variant="p" sx={{ fontSize: [0] }}>
                          * Acconsento a che i miei dati personali vengano
                          utilizzati in accordo con la
                          <OutboundLink
                            href="http://www.aquarial.it/it/privacy"
                            target="_blank"
                          >
                            {" "}
                            Privacy
                          </OutboundLink>{" "}
                          e{" "}
                          <OutboundLink
                            href="http://www.aquarial.it/it/cookie"
                            target="_blank"
                          >
                            Cookie Policy
                          </OutboundLink>
                        </Text>
                      </Box>
                    </LabeledCheckbox>
                    <LabeledCheckbox>
                      <Box>
                        <Text variant="p" sx={{ fontSize: [0] }}>
                          Acconsento all’uso dei miei dati personali per essere
                          aggiornato sui nuovi arrivi, prodotti in esclusiva e
                          per le finalità di marketing diretto correlate ai
                          servizi offerti e ricevere proposte in linea con i
                          miei interessi attraverso l’analisi dei miei
                          precedenti acquisti
                        </Text>
                      </Box>
                    </LabeledCheckbox>
                    <SubmitButton success={success} loading={loading} />
                    {error && (
                      <Text
                        variant="p"
                        sx={{ color: "light", mt: [1], fontSize: [0] }}
                      >
                        Si è verificato un errore durante l'invio della
                        richiesta. Riprova.
                      </Text>
                    )}
                  </Grid>
                </Box>
              </form>
            </Box>
          </Grid>
          <Box
            sx={{
              width: "90%",
              backgroundColor: "primary",
              height: "24px",
              margin: "0 auto",
            }}
          ></Box>
        </Box>
      </Container>
    </Box>
  )
}

const LabeledCheckbox = ({ children, defaultChecked, ...props }) => {
  const [checked, setChecked] = useState(defaultChecked)
  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        fontWeight: checked ? "bold" : "normal",
        color: "light",
        svg: {
          color: "primary",
        },
        mt: [3, 3, 3, 3],
      }}
    >
      <Checkbox
        checked={checked}
        onChange={() => setChecked(!checked)}
        {...props}
      />
      {children}
    </Label>
  )
}

const SubmitButton = ({ success, loading }) => (
  <Button
    type="submit"
    variant="buttons.full.primaryForm"
    sx={{
      mt: [3, 3, 3, 3],
      backgroundColor: success ? "#2ECC40" : "primary",
      "&:disabled": {
        cursor: "default",
        pointerEvents: "none",
      },
      fontWeight: "bold",
      fontFamily: "heading",
    }}
    disabled={success || loading}
  >
    <Flex
      sx={{
        justifyContent: success ? "center" : "space-between",
        alignItems: "center",
        gap: [3],
      }}
    >
      {loading
        ? "Caricamento..."
        : success
        ? "Richiesta inviata!"
        : "Invia richiesta"}
      {!success && <ArrowRight size="24" color="white" />}
    </Flex>
  </Button>
)

export default ContactForm
